<template>
  <v-container class="timeslot-selector fill-height mt-1">
    <v-layout fill-height column>
      <div class="d-flex justify-center">
        <v-img
          class="logo-modal"
          src="/img_layout/modal-logo.png"
          alt="Logo tosano"
          contain
          height="80px"
          width="80px"
        >
        </v-img>
      </div>
      <h3 class="font-weight-bold mx-auto my-5 text-center" v-html="title"></h3>
      <div v-if="description" v-html="description"></div>
      <!-- <div
        class="d-flex flex-column align-center justify-center default--text"
        v-if="category.metaData && category.metaData.category_info"
      >
        <div
          class="text-h4 text-sm-h3 font-weight-bold mb-6 text-center text-sm-left mt-12 mt-sm-0"
          v-html="category.metaData.category_info.TITLE"
        />
      </div> -->
      <!-- Blocco header -->
      <v-container
        :fluid="isFluid"
        class="header mb-3"
        v-if="headerProposals.length > 0"
      >
        <div class="mt-5">
          <component
            :is="headerMode"
            :title="headerTitle"
            :proposals="headerProposals"
            :autoplay="true"
            paginationClass="pagination-header-timeslot"
            cols="1"
            sm="1"
            md="1"
            lg="1"
          />
        </div>
      </v-container>

      <v-row
        no-gutters
        class="text-body-2 w-100 flex-nowrap"
        align="center"
        justify="space-between"
      >
        <div class="d-flex">
          <v-icon class="mr-2" color="secondary">$storefront</v-icon>
          <div>
            <div
              class="text-uppercase font-weight-bold secondary--text"
              style="line-height: 18px;"
            >
              {{ $t("timeslots.labelByService." + getDeliveryServiceId) }}
            </div>
            <div style="line-height: 18px;">
              <span class="font-weight-bold">{{
                cart.shippingAddress.addressName
              }}</span>
              <span class="mx-1">-</span>
              {{
                $t(
                  "navbar.address." +
                    cart.shippingAddress.addressTypeId +
                    ".list",
                  cart.shippingAddress
                )
              }}
              <v-icon
                small
                @click="changeAddress"
                v-if="!$vuetify.breakpoint.xs"
                color="black"
                class="ml-1"
                style="line-height: 18px;"
              >
                $edit
              </v-icon>
            </div>
            <span
              v-if="getDeliveryServiceId == 2"
              class="delivery-fee-timeslot-container white--text primary font-weight-bold text-uppercase"
              >Spese di spedizione: {{ cart.deliveryFee }} €
            </span>
          </div>
        </div>
        <!-- icon for mobile -->
        <v-icon
          @click="changeAddress"
          v-if="$vuetify.breakpoint.xs"
          color="black"
        >
          $edit
        </v-icon>
        <div v-else>
          <v-img
            width="100"
            height="90"
            contain
            :src="getServiceParams(getDeliveryServiceId).icon"
            :alt="getServiceParams(getDeliveryServiceId).name"
          />
          <span
            class="delivery-service-name white--text primary font-weight-bold text-uppercase"
            >{{ getServiceParams(getDeliveryServiceId).alias }}</span
          >
        </div>
      </v-row>
      <p
        class="selector-warning selector-warning-under-general-warning text-center"
        v-if="
          timeslotRestrictions.underLeadTimeItems ||
            timeslotRestrictions.dayLock ||
            timeslotRestrictions.lockCutoff
        "
      >
        {{ $t("timeslots.generalWarning") }}
      </p>

      <v-divider></v-divider>
      <div class="text-body-2 font-weight-bold text-uppercase mb-4">
        {{ $t("timeslots.dayLabel") }}
      </div>
      <v-tabs
        hide-slider
        height="auto"
        icons-and-text
        v-model="tab"
        center-active
        slider-size="1"
        show-arrows
        fixed-tabs
      >
        <v-tabs-slider></v-tabs-slider>
        <v-tab
          v-for="timeslotDay in days"
          :key="timeslotDay.dateIso"
          @click="selectTimeslotDay(timeslotDay)"
        >
          <div
            class="time-div d-flex flex-column align-center justify-space-around mb-0"
          >
            <template v-if="$dayjs().isSame(timeslotDay.dateIso, 'day')">
              <span class="day-number font-weight-bold">
                OGGI
              </span>
            </template>
            <template v-else>
              <span class="day-string">
                {{ $dayjs(timeslotDay.dateIso).format("dddd") }}
              </span>
              <span class="day-number font-weight-bold">
                {{ $dayjs(timeslotDay.dateIso).format("D") }}
              </span>
              <span class="month">
                {{ $dayjs(timeslotDay.dateIso).format("MMMM") }}
              </span>
            </template>
          </div>
        </v-tab>
      </v-tabs>
      <v-divider></v-divider>

      <div class="text-body-2 font-weight-bold text-uppercase mb-4">
        {{ $t("timeslots.timeLabel") }}
      </div>

      <v-tabs-items v-model="tab">
        <v-tab-item
          class="timeslot-container"
          v-for="timeslotDay in days"
          :key="timeslotDay.dateIso"
        >
          <v-row dense v-if="timeslotDay.timeslots.length > 0" no-gutters>
            <v-col
              cols="6"
              md="3"
              v-for="timeslot in timeslotDay.timeslots.filter(
                checkDeliveryDayPeriodId
              )"
              :key="timeslot.timeslotId"
            >
              <v-card class="timeslot-card" flat :disabled="disabled">
                <div
                  v-on:click="selectTimeslot(timeslot)"
                  class="timeslot-button d-flex justify-center"
                  :class="[
                    timeslot.status,
                    { 'under-lead-time': timeslot.underLeadTime },
                    { 'under-lock-day': timeslot.underLockDay },
                    { 'under-lock-cutoff': timeslot.underLockCutoff },
                    { selected: timeslot.selected }
                  ]"
                >
                  <!-- <span class="lock-icons">
                    <v-icon
                      v-if="timeslot.underLeadTime"
                      x-small
                      class="lead-time"
                    >
                      $clock
                    </v-icon>
                    <v-icon
                      v-if="timeslot.underLockDay"
                      x-small
                      class="day-lock"
                      aria-label=""
                    >
                      $clock
                    </v-icon>
                    <v-icon
                      v-if="timeslot.underLockCutoff"
                      x-small
                      class="lock-cutoff"
                    >
                      $clock
                    </v-icon>
                  </span> -->
                  <span class="lock-icons">
                    <v-icon
                      v-if="
                        timeslot.underLeadTime ||
                          timeslot.underLockDay ||
                          timeslot.underLockCutoff
                      "
                      x-small
                    >
                      $clock
                    </v-icon>
                  </span>
                  <span class="timeslot-time"
                    >{{ timeslot.beginTime }} - {{ timeslot.endTime }}</span
                  >
                </div>
              </v-card>
            </v-col>
          </v-row>
          <p v-else>
            {{ $t("timeslots.noSlots") }}
          </p>
        </v-tab-item>
      </v-tabs-items>

      <v-row
        no-gutters
        align="center"
        justify="space-between"
        justify-sm="start"
        class="range-buttons mt-5 mb-3"
      >
        <v-col
          cols="auto"
          sm="auto"
          v-for="(range, index) in timeslotRange"
          :key="index"
          class="d-flex justify-center mr-0 mr-sm-2"
        >
          <v-btn
            depressed
            class="rounded-md"
            :class="
              rangeTab === range.periodId
                ? 'secondary white--text active-border'
                : 'white default--text text--darken-2'
            "
            :min-width="$vuetify.breakpoint.xs ? 'auto' : 120"
            :small="$vuetify.breakpoint.xs"
            data-test="range-btn"
            :value="range.periodId"
            min-height="38"
            @click="rangeTab = range.periodId"
          >
            <v-icon
              v-if="range.icon"
              class="mr-2 timeslot-period-icon"
              :color="rangeTab === range.periodId ? 'white' : 'default'"
              >{{ range.icon }}</v-icon
            >
            <span class="range-text font-weight-normal">{{
              $t(range.text)
            }}</span>
          </v-btn>
        </v-col>
      </v-row>

      <v-row class="legend mt-10 mt-md-0" no-gutters>
        <v-spacer></v-spacer>
        <v-col cols="12" sm="3" class="py-0">
          <div class="timeslot-legend">
            <div class="marker empty">
              <span class="text-body-2">{{
                $t("timeslots.legend.empty")
              }}</span>
            </div>
          </div>
        </v-col>
        <v-col cols="12" sm="3" class="py-0">
          <div class="timeslot-legend">
            <div class="marker intermediate">
              <span class="text-body-2">{{
                $t("timeslots.legend.intemediate")
              }}</span>
            </div>
          </div>
        </v-col>
        <v-col cols="12" sm="3" class="py-0">
          <div class="timeslot-legend">
            <div class="marker full">
              <span class="text-body-2">{{ $t("timeslots.legend.full") }}</span>
            </div>
          </div>
        </v-col>
        <v-spacer></v-spacer>
      </v-row>

      <!-- blocco footer -->
      <v-container
        :fluid="isFluid"
        class="footer footer-timeslot"
        v-if="footerProposals.length > 0"
      >
        <component
          :is="footerMode"
          :title="footerTitle"
          :proposals="footerProposals"
          :autoplay="true"
          paginationClass="pagination-footer-timeslot"
          cols="auto"
          sm="auto"
          md="auto"
          lg="auto"
        />
      </v-container>
    </v-layout>
  </v-container>
</template>
<style scoped lang="scss">
// .lock-icons {
//   position: absolute;
//   top: 0;
//   right: 7px;
//   .lead-time {
//     color: $lead-time-color;
//   }
//   .day-lock {
//     color: $day-lock-color;
//   }
//   .lock-cutoff {
//     color: $lock-cutoff-color;
//   }
// }
.timeslot-selector {
  color: var(--v-default-base);
  height: 100%;
  .category-title {
    h1 {
      text-align: center;
      font-weight: 700 !important;
      font-size: 26px;
    }
  }
  .range-buttons {
    .v-btn {
      border: 1px solid $gray-border-color !important;
    }
    .active-border {
      border: 1px solid var(--v-secondary-lighten1) !important;
    }
    @media (max-width: 375px) {
      .timeslot-period-icon {
        display: none;
      }
    }
  }

  .selector-warning {
    margin: 0;
    @media #{map-get($display-breakpoints, 'sm-and-down')} {
      font-size: 14px;
    }
    &.selector-warning-under-lead-time {
      color: darken($lead-time-color, 30%);
    }
    &.selector-warning-under-lock-day {
      color: darken($day-lock-color, 30%);
    }
    &.selector-warning-under-lock-cutoff {
      color: darken($lock-cutoff-color, 30%);
    }
  }
  .v-tabs {
    flex-grow: unset;
  }
  .v-slide-group__wrapper {
    background-color: white;
  }
  .v-divider {
    margin-top: 10px;
    margin-bottom: 10px;
  }
  .title {
    font-size: 25px;
  }
  .subtitle {
    font-size: 16px;
  }
  .timeslot-container {
    flex-wrap: wrap;
    justify-content: center;
    align-content: flex-start;
    min-height: 200px;
    overflow-y: auto;
    @media #{map-get($display-breakpoints, 'md-and-down')} {
      height: 100%;
    }
  }
  .legend {
    flex-grow: unset;
    margin-top: 10px !important;
  }
  .timeslot-card {
    overflow: hidden;
    justify-content: center;
  }
  .timeslot-button {
    border-radius: 5px;
    border: 1px solid #e5e5e5;
    margin: 3px;
    font-size: 15px;
    font-weight: normal;
    pointer-events: auto;
    cursor: pointer;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 9px;
    position: relative;
    color: $text-color;

    .timeslot-time {
      font-size: 16px;
    }
  }
  .timeslot-legend {
    margin: 3px;
    .marker {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 40px;
      border-radius: 4px;
      span {
        vertical-align: middle;
      }
    }
  }

  .empty {
    color: $text-color;
    background-color: #c2eeb0;
    border: 1px solid #c2eeb0;
  }
  .intermediate {
    color: $text-color;
    background-color: #fdce70;
    border-color: #fdce70;
  }
  .full {
    color: $text-color;
    background-color: var(--v-primary-lighten3);
    border-color: var(--v-primary-lighten3);
  }
  .selected {
    color: #fff;
    background-color: var(--v-secondary-lighten1);
    border-color: var(--v-secondary-lighten1);
  }
  .v-tab {
    border: 1px solid var(--v-grey-lighten2);
    background-color: var(--v-grey-lighten2);
    color: var(--v-secondary-lighten1);
    padding: 0px !important;
    margin: 0px 5px 0px 5px !important;
    border-radius: 5px;
    min-width: 76px;
    min-height: 76px;
    text-transform: capitalize;

    &:hover {
      background-color: var(--v-grey-lighten1);
    }
    @media #{map-get($display-breakpoints, 'sm-and-up')} {
      min-width: 70px;
      min-height: 70px;
      height: 76px;
      width: 100px !important;
    }
    .time-div {
      color: $text-color;
      .day-string {
        margin-bottom: 0px;
        font-size: 13px;
        letter-spacing: 0.1px;
      }
      .day-number {
        font-size: 20px;
        letter-spacing: 0.17px;
        line-height: 1.2;
      }
      .month {
        font-weight: bold;
        margin-top: 0px;
        font-size: 13px;
        letter-spacing: 0.1px;
      }
    }
  }
  .v-tab.v-tab--active {
    border-radius: 5px;
    color: $white;
    background-color: var(--v-secondary-lighten1);
    .time-div {
      color: $white;
    }
  }

  .v-slide-group__content {
    height: 100%;
  }
}
@media #{map-get($display-breakpoints, 'xs-only')} {
  .timeslot-selector {
    .timeslot-button {
      padding: 10px 5px;
      .timeslot-time {
        font-size: 15px;
      }
      .timeslot-status {
        font-size: 10px;
      }
    }
  }
}
.delivery-service-name {
  white-space: nowrap;
}

.delivery-fee-timeslot-container {
  font-size: 12px;
  text-align: center;
  line-height: 1.2;
  padding: 1px 12px;
  white-space: nowrap;
}
</style>
<script>
import DeliveryService from "~/service/deliveryService";
import CategoryService from "~/service/categoryService";
import { forEachCartItem } from "~/service/ebsn";

import { mapState, mapGetters } from "vuex";
import toNumber from "lodash/toNumber";
import isNumber from "lodash/isNumber";
import split from "lodash/split";
import get from "lodash/get";

import { mapProposalComponent } from "~/service/ebsn.js";

export default {
  name: "TimeslotSelector",
  components: {
    ProposalProductListSlider: () =>
      import("@/components/proposal/ProposalProductListSlider.vue"),
    ProposalProductGrid: () =>
      import("@/components/proposal/ProposalProductGrid.vue"),
    ProposalSlider: () => import("@/components/proposal/ProposalSlider.vue"),
    ProposalGrid: () => import("@/components/proposal/ProposalGrid.vue")
  },
  data() {
    return {
      days: {},
      tab: 0,
      category: {},
      selectedTimeslotDay: {},
      disabled: false,
      rangeTab: [0],
      deliveryFee: null,
      // configuration
      // timeslotPeriodId: 1 mattina drive, 2 pome drive, 3 sera drive, 4 mattina home, 5 pome home, 6 sera home
      timeslotRange: {
        0: { periodId: [0], text: "timeslots.allTimeRanges" },
        1: { periodId: [1, 4], icon: "$day", text: "timeslots.morning" },
        2: {
          periodId: [2, 3, 5, 6, 7, 8, 9],
          icon: "$night",
          text: "timeslots.afternoon"
        }
      }
    };
  },
  computed: {
    ...mapProposalComponent({
      header: "header",
      footer: "footer"
    }),
    ...mapState({
      cart: ({ cart }) => cart.cart
    }),
    ...mapGetters({
      getDeliveryServiceId: "cart/getDeliveryServiceId"
    }),
    title() {
      return get(
        this.category,
        "metaData.category_info.TITLE",
        this.category.name
      );
    },
    description() {
      return get(
        this.category,
        "metaData.category_info.DESCRIPTION",
        this.category.description
      );
    },
    timeslotRestrictions() {
      var result = {};
      let maxLeadTime = 0;
      forEachCartItem(this.cart, item => {
        let leadTime = toNumber(item.product.productInfos.LEAD_TIME);
        if (isNumber(leadTime) && leadTime > maxLeadTime) {
          maxLeadTime = leadTime;
        }
        result.underLeadTimeItems = maxLeadTime > 0;

        if (item.product.productInfos.DAY_LOCK) {
          if (!result.dayLock) result.dayLock = [];
          let array = split(item.product.productInfos.DAY_LOCK, " ");
          if (item.product.productInfos.DAY_LOCK.indexOf(",") > -1) {
            array = split(item.product.productInfos.DAY_LOCK, ",");
          }
          for (let i = 0; i < array.length; i++) {
            if (array[i].indexOf("_") > -1) {
              // "day_deliveryDayPeriodId, es: lun_2"
              let daySplitted = array[i].split("_");
              result.dayLock.push({
                day: daySplitted[0],
                deliveryDayPeriodId: daySplitted[1]
              });
            } else {
              // "day, es: lun"
              result.dayLock.push({
                day: array[i],
                deliveryDayPeriodId: undefined
              });
            }
          }
        }

        if (item.product.productInfos.PRODUCT_LOCK_CUTOFF) {
          if (!result.lockCutoff) result.lockCutoff = [];
          let arrayLock = split(
            item.product.productInfos.PRODUCT_LOCK_CUTOFF,
            " "
          );
          if (item.product.productInfos.PRODUCT_LOCK_CUTOFF.indexOf(",") > -1) {
            arrayLock = split(
              item.product.productInfos.PRODUCT_LOCK_CUTOFF,
              ","
            );
          }
          // arrayLock[0] = "16:00-18:00";
          for (var i = 0; i < arrayLock.length; i++) {
            let hours = arrayLock[i].split("-");
            result.lockCutoff.push(hours);
          }
        }
      });
      result.leadTimeLimit = this.$dayjs().add(maxLeadTime, "hour");
      // result.dayLock = null;
      return result;
    }
  },
  methods: {
    changeAddress() {
      this.$emit("submit", "setAddress");
    },
    async openAddressSelector() {
      if (await this.setAddress()) {
        await this.needTimeslot();
      }
    },
    checkIfIsToday(date) {
      return this.$dayjs().isSame(date, "day");
    },
    checkDeliveryDayPeriodId(item) {
      return (
        this.rangeTab == 0 || this.rangeTab.includes(item.deliveryDayPeriodId)
      );
    },
    selectTimeslotDay(day) {
      this.selectedTimeslotDay = day;
    },
    async selectTimeslot(timeslot) {
      let _this = this;
      if (timeslot.active < 1) {
        return;
      }
      _this.disabled = true;
      let data = await _this.$store.dispatch("cart/setTimeslot", {
        dateIso: _this.selectedTimeslotDay.dateIso,
        timeslotId: timeslot.timeslotId
      });
      if (data) {
        this.$emit("submit", true);
      }
    },
    async fetchTimeslots() {
      let _this = this;
      _this.days = await DeliveryService.getTimeslotList(false);

      if (_this.days.length > 1 && _this.days[0].timeslots.length == 0) {
        _this.days.shift();
      }

      for (var i = 0; i < _this.days.length; i++) {
        if (_this.days[i].dateIso == _this.cart.timeslot.date) {
          _this.tab = i;
          this.selectedTimeslotDay = _this.days[i];
          break;
        }
      }
      if (!_this.selectedTimeslotDay.dateIso) {
        _this.selectTimeslotDay(_this.days[0]);
      }
      return false;
    },
    getServiceParams(serviceId) {
      return this.$t("navbar.service." + serviceId);
    },
    async fetchCategory() {
      try {
        this.category = await CategoryService.getCategoryBySlug(
          "timeslot-selector"
        );
      } catch (e) {
        console.log(e);
      }
    },
    async getDeliveryFee() {
      this.deliveryFee =
        this.getDeliveryServiceId == 2
          ? await DeliveryService.getDeliveryFee()
          : (this.deliveryFee = null);
    }
  },
  async mounted() {
    this.disabled = false;
    this.fetchTimeslots();
    this.fetchCategory();
    await this.getDeliveryFee();
  }
};
</script>
