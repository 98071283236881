import { render, staticRenderFns } from "./AddressSelector.vue?vue&type=template&id=6596030e&scoped=true&"
import script from "./AddressSelector.vue?vue&type=script&lang=js&"
export * from "./AddressSelector.vue?vue&type=script&lang=js&"
import style0 from "./AddressSelector.vue?vue&type=style&index=0&id=6596030e&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6596030e",
  null
  
)

export default component.exports